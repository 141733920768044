// ! NEW APP STYLES

@import 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap';

:root {
  --app-primary: #62fa9f;
  --app-secondary: #2c3999;
  --app-dark: #404040;
  --app-darker: #212429;
  --app-dark-75: #7d7a7a;
  --app-white: white;
  --app-white-75: #f0f0f0;
  --app-white-50: #d9d9d9;
  --app-text-dark: #3f464f;
  --app-text-green: #1a8557;
  --app-danger: #db4039;
  --app-success: #b3d660;
  --app-warning: #ffa51e;
  --app-shadow-xs-50: 0px 0px 2px 0px rgba(0 0 0 / 50%);
  --app-shadow-sm-25: 2px 2px 4px 0px rgba(0 0 0 / 25%);
  --app-shadow-md-25: 0px 4px 4px 0px rgba(0 0 0 / 25%);
  --app-shadow-md-10: 0px 4px 4px 0px rgba(0 0 0 / 10%);
  --app-shadow-md-10-filter: 0px 4px 4px rgba(0 0 0 / 10%);

  @media (width > 1921px) {
    font-size: 20px;
  }
}

.ant-dropdown-menu-item {
  .ant-menu-title-content {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  &.sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
  }

  &:hover {
    background-color: var(--app-secondary);
    color: var(--app-white);

    input {
      border: 1px solid var(--app-white);
    }

    path {
      fill: var(--app-white);
    }
  }
}

.ant-tooltip-inner {
  background: var(--app-white-75) !important;
  border-radius: 0.5rem;
  box-shadow: none;
  color: var(--app-dark);
  box-shadow: var(--app-shadow-md-10);
}

.ant-tooltip-arrow-content {
  &::before {
    box-shadow: var(--app-shadow-xs-50);
    background: var(--app-white-75) !important;
  }
}

*input {
  outline: none;

  &:focus-visible,
  &:focus,
  &:focus-within,
  &:hover,
  &:target {
    outline: none;
  }
}

.ant-picker {
  width: 100%;
  outline: none;

  &:focus-visible,
  &:focus,
  &:target,
  &:hover,
  &:target {
    outline: 1px solid transparent;
  }

  > div {
    outline: none;

    &:focus-visible,
    &:focus,
    &:target,
    &:hover,
    &:target {
      outline: none;
    }
  }

  // .ant-picker-input {
  //   outline: none;
  //   border: none;

  //   &:focus-visible,
  //   &:focus,
  //   &:target,
  //   &:hover,
  //   &:target {
  //     outline: none;
  //   }

  //   input {
  //     border: none;
  //     text-align: center;

  //     &:focus-visible,
  //     &:focus,
  //     &:target,
  //     &:hover,
  //     &:target {
  //       outline: none;
  //     }
  //   }
  // }
}

.single-round-btn {
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  height: 2rem;
  box-shadow: var(--app-shadow-md-25);
  border: solid 1px var(--app-white-50);
  border-radius: 1rem;
  width: 2rem;
  align-items: center;
  background-color: var(--app-white);
  align-self: end;
}

.ant-select-dropdown {
  border-radius: 0.5rem;
  padding: 0;
  border: var(--app-white-50) 1px solid;
  box-shadow: var(--app-shadow-sm-25);
}

.ant-select-item-option-selected {
  &:not(.ant-select-item-option-disabled) {
    background-color: var(--app-dark);
    color: var(--app-white-75);
  }
}

.ant-select-item-option {
  &:hover {
    background-color: var(--app-secondary);
    color: var(--app-white-75);
  }
}

.ant-modal-content {
  height: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
.ant-modal-close-x {
  height: 55px;
}
.ant-modal {
  border-radius: 1rem;
  padding: 0;

  .ant-modal-header,
  .ant-modal-close {
    background-color: var(--app-dark);

    * {
      color: var(--app-white-75);
    }
  }

  .ant-spin-nested-loading {
    overflow: auto;
    height: 100%;
  }
}

.ant-btn {
  border-radius: 0.5rem;
  border: 1px solid var(--app-dark) !important;
  color: var(--app-dark) !important;

  i {
    color: var(--app-dark);
  }

  &.ant-btn-primary {
    color: var(--app-white) !important;
    background-color: var(--app-secondary) !important;

    i,
    span {
      color: var(--app-white) !important;
    }

    &:hover,
    &:focus {
      color: var(--app-white) !important;
      background-color: color-mix(
        in srgb,
        var(--app-secondary),
        var(--app-dark) 25%
      ) !important;
    }
  }

  &:hover {
    color: var(--app-secondary) !important;
    border: 1px solid var(--app-secondary) !important;
  }

  &.ant-btn-circle {
    border-radius: 50%;
  }
}

.ant-input {
  line-height: calc(1.5rem - 1px);
}

.ant-input-search {
  .ant-input-group > .ant-input-group-addon:last-child {
    top: 2px;

    & .ant-btn-primary {
      box-shadow: none;
    }
  }
}

button.ant-switch {
  background-color: var(--clr-base-dk);
  border: none;

  &:hover,
  &:focus,
  &:active {
    border: none;
  }
}

button.ant-switch {
  color: var(--clr-secondary) !important;
  background-color: var(--clr-base-dk);
  border: 1px solid var(--clr-secondary-ltr) !important;

  &:hover,
  &:focus,
  &:active {
    border: none;
    box-shadow: none;
  }
}

.ant-switch-checked {
  background-color: var(--clr-base-lt) !important;
}

span.ant-switch-handle {
  border-radius: 50%;
  box-shadow: 1px 0 5px var(--clr-secondary);
}

nz-switch {
  display: flex;
  align-items: center;
}

.ant-switch {
  box-shadow: none !important;
}

.panth-btn {
  color: var(--app-white);
  border-radius: 0.5em !important;

  &.primary {
    border: 1px solid var(--clr-secondary-ltr) !important;
    background: var(--app-secondary) !important;

    &:hover {
      background: color-mix(
        in srgb,
        var(--app-secondary) 75%,
        black
      ) !important;
    }
  }

  &.error {
    background-color: transparent;
    color: var(--clr-secondary-lt);
    border: 1px solid var(--clr-secondary-lt);

    &:hover {
      border: 1px solid var(--clr-req-star);
    }
  }
}

.ant-switch-inner {
  color: var(--clr-secondary);
}

.state.selected.ng-star-inserted {
  background-color: var(--clr-base-dk);
  color: var(--clr-secondary);
}

.control-wrapper {
  .states {
    .state {
      background-color: var(--clr-base);
      border: 1px solid var(--clr-secondary-ltr);
      color: var(--clr-secondary);

      &.selected {
        background-color: var(--clr-primary);
        color: var(--clr-neutral-lt);
      }
    }
  }
}
