// TAILWIND
@tailwind base;
@tailwind components;
@tailwind utilities;

@import './app/styles/dashboard';

:root {
  --display-light-logo: 'inline'; // -------------------------------------------------------------------------------> --display-light-logo
  --display-dark-logo: 'none'; // -------------------------------------------------------------------------------> --display-dark-logo
  --clr-secondary-rgb: 51, 48, 60; // -------------------------------------------------------------------------------> --clr-secondary-rgb
  --clr-secondary: #33303cad; // ----------------------------------------------------------------------------------> --clr-secondary
  --clr-secondary-lt: #33303cde; // -------------------------------------------------------------------------------->  --clr-secondary-lt
  --clr-primary: #28c76f; // ----------------------------------------------------------------------------------->  --clr-primary
  --clr-primary-lt: #28c76f;
  --clr-req-star: red;
  --clr-neutral-lt: #fff;

  // Used as background-color in body and several other elements

  --clr-base: #f8f7fa; // ------------------------------------------------------------------------------> --clr-base
  // Background color of widgets, navbar and other components
  --background-secondary: #fff;
  --background-tertiary: #f7f8fa;
  --background-quaternary: #7367f0;
  --border-style-light: rgb(var(--font-default-rgb) 0);
  --shadow-style-light: rgb(var(--font-default-rgb) 0.05);
  --ant-primary-color: var(--app-secondary);
  --ant-primary-1: color-mix(in srgb, var(--app-secondary) 70%, white);
  --ant-primary-2: color-mix(in srgb, var(--app-secondary) 80%, white);
  --ant-primary-3: color-mix(in srgb, var(--app-secondary) 90%, white);
  --ant-primary-4: var(--app-secondary);
  --ant-primary-5: color-mix(in srgb, var(--app-secondary) 90%, black);
  --ant-primary-6: color-mix(in srgb, var(--app-secondary) 80%, black);
  --ant-primary-7: color-mix(in srgb, var(--app-secondary) 70%, black);

  // ? ant error colors to be overrided
  --ant-error-color: var(--app-danger);
  --ant-error-color-hover: color-mix(in srgb, var(--app-danger) 90%, black);
  --ant-error-color-active: color-mix(in srgb, var(--app-danger) 90%, white);
  --ant-error-color-outline: color-mix(
    in srgb,
    var(--app-danger) 20%,
    transparent
  );
}

html,
body {
  @apply h-full m-0 p-0;
  font-family: Roboto, monospace;
}

//! OVERIDES

//? INPUTS

@layer components {
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    @apply appearance-none m-0;
  }
}

//? SCROLLBARS

*::-webkit-scrollbar-track {
  @apply rounded-lg;
}

*::-webkit-scrollbar {
  @apply rounded-lg bg-app-white-50 w-2 h-2;
}

*::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-app-dark-75 border-2  border-app-white-50 hover:bg-app-dark;
}

.ant-table-column-sorters {
  span {
    @apply whitespace-nowrap;
  }
}

//? ANT TABLE
.ant-table-pagination {
  border-top: 1px solid var(--app-white-50);
  padding-top: 0.5rem;
  justify-content: center;
  margin-bottom: 0 !important;
  margin-top: 1rem !important;
}

.ant-table {
  @apply h-full rounded-2xl overflow-hidden border border-app-white-50;
}

.ant-pagination-item-link {
  display: flex !important;
  border-radius: 0.5rem !important;
  @apply justify-center items-center;
}

.ant-pagination-item {
  @apply rounded-lg;
}

//? ANT DROPDOWN
.ant-dropdown-trigger {
  @apply flex items-center;
}

//? ANT PROGRESS BAR
.ant-progress-inner {
  @apply border border-app-white shadow-xs-50;
}

//? ANT TABLE PAGINATION SIZE
.ant-select-selector {
  @apply rounded-2xl;
}

.ant-picker-now-btn {
  @apply text-white hover:text-app-primary bg-app-secondary rounded-md px-2 py-1 hover:bg-app-secondary-hover duration-300;
}

//? LEAFLET WINDY INFO
.leaflet-control-velocity {
  margin-bottom: 0 !important;
}

//? ANT TABS
.ant-tabs-nav {
  overflow: hidden;
}
